<template>
  <div class="details">
    <van-image
      width="100%"
      fit="cover"
      :src="require('@/assets/img/ProductServe_14.jpg')"
    />
    <h2>八大核心价值、助力企业合法、合规、高效经营管理</h2>
    <div class="business_list">
      <div v-for="item in 3" :key="item" class="business_list_box">
        <h3>智能账务处理、业财无缝连接</h3>
        <p>这是简介这是简介这是简介这是简介这是简介</p>
        <p>这是简介这是简介这是简介这是简介</p>
        <p>这是简介这是简介这是简介这是简介</p>
      </div>
    </div>
    <van-image
      width="100%"
      fit="cover"
      :src="require('@/assets/img/ProductServe_15.jpg')"
    />
    <div class="features">
      <div class="features_nav">
        <div
          :class="active == item ? 'active' : ''"
          v-for="item in 8"
          :key="item"
          @click="activeFeatures(item)"
        >
          这是特点
        </div>
      </div>
      <div class="features_img">
        <van-image
          width="80%"
          fit="cover"
          :src="require('@/assets/img/ProductServe_16.jpg')"
        />
      </div>

      <h4 class="title">特点标题</h4>
      <div>
        这是特点内容这是特点内容这是特点内容这是特点内容这是特点内容这是特点内容这是特点内容
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage } from "vant";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [VanImage.name]: VanImage,
    copyrightIp,
  },
  data() {
    return {
      images: [
        require("@/assets/img/ProductServe_14.jpg"),
        require("@/assets/img/ProductServe_15.jpg"),
        require("@/assets/img/ProductServe_16.jpg"),
      ],
      active: "1",
    };
  },
  methods: {
    activeFeatures(data) {
      this.active = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.details {
  h2 {
    width: 100%;
    padding: 15px;
    text-align: center;
  }
  .business_list {
    width: 100%;
    padding: 20px;
    background: #fff;
    .business_list_box {
      padding-bottom: 20px;
      h3 {
        font-weight: bold;
      }
      p {
        padding-top: 10px;
      }
    }
  }
  .features {
    width: 100%;
    padding: 10px;
    background: #fff;
    .features_nav {
      width: 100%;
      padding: 10px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      text-align: center;
      background: #f2f2f2;
      border-radius: 8px;
      div {
        transition: 0.3s;
      }
      .active {
        background: #197be8;
        color: #fff;
        border-radius: 20px;
      }
    }
    .features_img {
      width: 100%;
      padding: 10px 0;
      text-align: center;
    }
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 5px;
    }
  }
}
</style>
